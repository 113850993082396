import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/utils/utils"

const badgeVariants = cva(
  "text-500-12 inline-flex items-center rounded-full border px-2 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground",
        high: "bg-emergency-level-1  !text-white ",
        medium: "bg-emergency-level-2 !text-emergency-level-1",
        low: "bg-emergency-level-3 !text-emergency-level-1",
        isSubtask: "bg-transparent border-yellow-dark",
        hasSubtask: "bg-yellow-light border-yellow-dark",
        forecast: "bg-blue-readable-blue text-300-14 text-black-dark",
        forecastYellow: "bg-yellow-light border-yellow-light text-black-dark text-300-14",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />
}

export { Badge, badgeVariants }
