import memoizeOne from "memoize-one"
import { pagesPath } from "@/utils/$path"
import { useLingui } from "@lingui/react"

const memoizedUrlMaker = memoizeOne(pagesPath._lang)

export const useUrlMaker = (): ReturnType<typeof pagesPath._lang> => {
  const { i18n } = useLingui()
  return memoizedUrlMaker(i18n.locale)
}
