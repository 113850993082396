/* eslint-disable complexity */
import { cn } from "@/utils/utils"
import "./chip.css"
import type { Task } from "@/utils/supabase/types"
import { formatDateFromDate } from "@/_entreprise/date_utilities"
import { Badge } from "@/components/ui/badge"
import { X } from "lucide-react"

export type ChipBrightness = "yellow" | "low" | "medium" | "high" | "water-green"

export function Chip({
  label,
  brightness,
  priority,
  planned,
  plannedOutline,
  printed,
  printedWithNodate,
  isAsk,
  parentNumber,
  subTaskOutline,
  isClosed,
  transparent,
  blue,
  onClick,
  customClasses,
  v2,
  isSubtask,
  hasSubtask,
  variant,
  onDelete,
}: {
  label?: React.ReactNode | string
  brightness?: ChipBrightness
  priority?: Task["priority"]
  planned?: boolean
  plannedOutline?: boolean
  printed?: Date
  printedWithNodate?: Date
  isAsk?: boolean
  parentNumber?: string
  subTaskOutline?: boolean
  isClosed?: boolean
  transparent?: boolean
  blue?: boolean
  onClick?: () => void
  customClasses?: string
  v2?: boolean
  isSubtask?: boolean
  hasSubtask?: boolean
  variant?: "forecast" | "forecastYellow"
  onDelete?: () => void
}) {
  // const style: CSSProperties = {
  //   backgroundColor: "black",
  //   border: "1px solid black",
  //   color: "white",
  //   width: "auto",
  //   display: "inline-block",
  //   padding: "0.25rem 0.5rem",
  // }

  if (priority === "none") {
    return null
  }

  const className = cn(
    "w-auto bg-black border-black  border border-transparent rounded-full",
    blue &&
      "bg-blue-readable-blue border-forecast-blue-readable text-forecast-blue-readable",
    transparent && "bg-transparent  border-black-dark text-black-dark",
    brightness === "yellow" && "bg-yellow-light border-yellow-dark ",
    subTaskOutline && "bg-transparent border-yellow-dark  ",
    priority === "high" && "bg-emergency-level-1  !text-white    ",
    priority === "medium" && "bg-emergency-level-2 !text-emergency-level-1",
    priority === "low" && "bbg-emergency-level-3 !text-emergency-level-1",
    planned && "bg-water-green  !text-white ",
    plannedOutline && "bg-transparent  !text-water-green border-water-green",
    (printed || printedWithNodate) && "bg-transparent  border-zinc-400",
    isAsk && " bg-black-dark !text-white ",
    parentNumber && " bg-transparent !text-black-dark border-black-dark",
    "text-500-12 px-[10px] py-1",
    isClosed && "bg-black-dark !text-white border-black-dark",
    isClosed &&
      (planned || printed) &&
      "bg-transparent !text-black-dark border-black-dark",

    customClasses,
  )

  let text = label
  if (priority === "high") {
    text = "Urgence haute"
  } else if (priority === "medium") {
    text = "Urgence moyenne"
  } else if (priority === "low") {
    text = "Urgence basse"
  } else if (planned || plannedOutline) {
    text = "Planifiée"
  } else if (printed) {
    text = `Imprimée ${formatDateFromDate(printed)}`
  } else if (printedWithNodate) {
    text = "Imprimée"
  } else if (isAsk) {
    text = "Demande"
  } else if (parentNumber) {
    text = `Tâche parente: ${parentNumber}`
  } else if (subTaskOutline) {
    text = "Sous-tâche"
  }

  // switch (brightness) {
  //   case "high":
  //     style.backgroundColor = "#18181B"
  //     break
  //   case "medium":
  //     style.backgroundColor = "#525252"
  //     break
  //   case "white":
  //     style.backgroundColor = "white"
  //     style.color = "black"
  //     break
  //   case "low":
  //   default:
  //     style.backgroundColor = "#71717A"
  //     break
  // }

  if (v2) {
    let _variant: string = variant ?? "default"
    if (priority === "high") {
      _variant = "high"
    } else if (priority === "medium") {
      _variant = "medium"
    } else if (priority === "low") {
      _variant = "low"
    } else if (isSubtask) {
      _variant = "isSubtask"
    } else if (hasSubtask) {
      _variant = "hasSubtask"
    } else if (_variant === "forecast") {
      _variant = "forecast"
    } else if (_variant === "forecastYellow") {
      _variant = "forecastYellow"
    }

    return (
      //@ts-ignore
      <Badge variant={_variant} className="relative group">
        <>
          {text}
          {onDelete && (
            <X
              onClick={onDelete}
              className="transition-opacity duration-300 group-hover:opacity-100 opacity-0 absolute top-1/2 right-[2px] -translate-y-1/2  w-4 h-4 bg-destructive cursor-pointer rounded-full"
            />
          )}
        </>
      </Badge>
    )
  }

  return (
    <div onClick={onClick} className={cn(className, "relative group")}>
      {text}
      {onDelete && (
        <X
          onClick={onDelete}
          className="transition-opacity duration-300 group-hover:opacity-100 opacity-0 absolute top-1/2 right-[2px] -translate-y-1/2  w-4 h-4 bg-destructive cursor-pointer rounded-full"
        />
      )}
    </div>
  )
}
