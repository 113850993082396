/* eslint-disable complexity */
// use Supabase to get data
"use server"

import { createClient } from "@/utils/supabase/server"
import type { Supabase, Task } from "@/utils/supabase/types"
import { randomUUID } from "node:crypto"
import { getNewTaskNumber } from "./services"
import { getCurrentUser } from "@/_slices/people/server/people-service"

// CREATE
export async function createTaskTemplate(taskId: string): Promise<Task | null> {
  const supabase = createClient()

  const creator = await getCurrentUser()
  if (!creator) {
    throw new Error("You must be logged in to view this page")
  }

  const { data: task } = await supabase.from("tasks").select().eq("id", taskId).single()

  if (task === null) {
    console.error("Error fetching task:", taskId)
    return null
  }

  if (task.is_template) {
    console.error("Error: task is already a template")
    return null
  }

  if (task.parent_id !== null) {
    console.error("Error: task is a subtask")
    return null
  }

  const template = { ...task } as Task

  template.title = `Modèle - ${template.title}`
  template.is_template = true
  template.id = randomUUID().toString()
  template.created_at = new Date().toISOString()
  template.author_id = creator.id
  template.edited_at = null
  template.editor_id = null
  template.status = "open"

  const { error } = await supabase.from("tasks").insert([template]).single()

  if (error) {
    console.error("Error creating task template:", error)
    return null
  }

  await copyRelatedData(supabase, taskId, template.id)

  return template
}

// CREATE
export async function createTaskTemplateFromScratch(): Promise<Task | null> {
  const supabase = createClient()

  const creator = await getCurrentUser()
  if (!creator) {
    throw new Error("You must be logged in to view this page")
  }

  const taskNumber = await getNewTaskNumber()
  if (taskNumber === null) {
    throw new Error("Impossible to get a new task number")
  }

  const template: Task = {
    title: "",
    is_template: true,
    id: randomUUID().toString(),
    created_at: new Date().toISOString(),
    author_id: creator.id,
    edited_at: null,
    editor_id: null,
    contact_person: null,
    contact_person_email: null,
    contact_person_phone: null,
    description: null,
    from_template: "00000000-0000-0000-0000-000000000000",
    organization_id: creator.organization_id,
    parent_id: null,
    printed_at: null,
    priority: "none",
    priority_id: null,
    remarks: null,
    request_date: null,
    requesting_organization: null,
    requesting_person: null,
    status: "open",
    task_number: taskNumber,
  }
  const { error } = await supabase.from("tasks").insert([template]).single()

  if (error) {
    console.error("Error creating task template:", error)
    return null
  }

  return template
}

export async function createTaskFromTemplate(templateId: string) {
  const supabase = createClient()

  const creator = await getCurrentUser()
  if (!creator) {
    console.error("Error fetching current user")
    return null
  }

  const template = await readTaskTemplate(templateId)
  if (!template) {
    console.error("Error fetching task template:", templateId)
    return null
  }

  const taskNumber = await getNewTaskNumber()
  if (taskNumber === null) {
    throw new Error("Impossible to get a new task number")
  }

  const task: Task = {
    ...template,
    is_template: false,
    from_template: templateId,
    id: randomUUID().toString(),
    created_at: new Date().toISOString(),
    author_id: creator.id,
    edited_at: null,
    editor_id: null,
    organization_id: creator.organization_id,
    parent_id: null,
    printed_at: null,
    status: "open",
    task_number: taskNumber,
  }
  const { error } = await supabase.from("tasks").insert([task]).single()
  if (error) {
    console.error("Error creating task template:", error)
    return null
  }

  await copyRelatedData(supabase, templateId, task.id)

  return task
}

// READ
export async function readTaskTemplate(templateId: Task["id"]): Promise<Task | null> {
  const supabase = createClient()

  const { data, error } = await supabase
    .from("tasks")
    .select()
    .eq("id", templateId)
    .single()

  if (error) {
    console.error("Error reading task template:", error)
    return null
  }

  return data
}

// UPDATE
export async function updateTaskTemplate(template: Task): Promise<Task | null> {
  const supabase = createClient()

  const { data, error } = await supabase.from("tasks").upsert([template]).single()

  if (error) {
    console.error("Error updating task template:", error)
    return null
  }

  return data
}

// DELETE
export async function deleteTaskTemplate(templateId: Task["id"]): Promise<Task | null> {
  const supabase = createClient()

  const { data, error } = await supabase
    .from("tasks")
    .delete()
    .eq("id", templateId)
    .single()

  if (error) {
    console.error("Error deleting task template:", error)
    return null
  }

  return data
}

// LIST
// export async function listTaskTemplate() {
//   const { data, error } = await supabase.from("tasks").select()

//   if (error) {
//     console.error("Error listing task templates:", error)
//     return []
//   }

//   return data
// }

export async function listTaskTemplate(): Promise<Task[]> {
  const supabase = createClient()

  const query = supabase
    .from("tasks")
    .select()
    .eq("is_template", true)
    .neq("status", "deleted")
    .not("edited_at", "is", null)
    .order("title", { ascending: true })

  let { data: taskList } = await query.returns<Task[]>()

  if (taskList === null) {
    taskList = []
  }

  return taskList
}

async function copyRelatedData(
  supabase: Supabase,
  fromTaskId: string,
  newTaskId: string,
) {
  // locations - should look if the task has locations and make a copy of them with different ID
  const locations = await supabase
    .from("task_addresses")
    .select()
    .eq("task_id", fromTaskId)

  if (locations.data && locations.data.length > 0) {
    const values = locations.data.map((location) => {
      return { ...location, task_id: newTaskId, id: randomUUID().toString() }
    })
    const { data: ___, error: __ } = await supabase
      .from("task_addresses")
      .insert(values)
      .throwOnError()
      .select()
  }

  // planned dates - should look if the task has planned_dates into task_planned_dates and make a copy of them with different ID
  const plannedDates = await supabase
    .from("task_planned_dates")
    .select()
    .eq("task_id", fromTaskId)

  if (plannedDates.data && plannedDates.data.length > 0) {
    const values = plannedDates.data.map((plannedDate) => {
      return { ...plannedDate, task_id: newTaskId }
    })
    const { data: ___, error: __ } = await supabase
      .from("task_planned_dates")
      .insert(values)
      .throwOnError()
      .select()
  }

  // teams - should look if the task has teams into task_teams and make a copy of them with different ID
  const teams = await supabase.from("task_teams").select().eq("task_id", fromTaskId)

  if (teams.data && teams.data.length > 0) {
    const values = teams.data.map((team) => {
      return { ...team, task_id: newTaskId, id: randomUUID().toString() }
    })
    const { data: ___, error: __ } = await supabase
      .from("task_teams")
      .insert(values)
      .throwOnError()
      .select()
  }

  // work_categories - should look if the task has work_categories into task_work_categories and make a copy of them with different ID
  const workCategories = await supabase
    .from("task_work_categories")
    .select()
    .eq("task_id", fromTaskId)

  if (workCategories.data && workCategories.data.length > 0) {
    const values = workCategories.data.map((workCategory) => {
      return { ...workCategory, task_id: newTaskId, id: randomUUID().toString() }
    })
    const { data: ___, error: __ } = await supabase
      .from("task_work_categories")
      .insert(values)
      .throwOnError()
      .select()
  }
}
