import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useUrlMaker } from "./useUrlMaker"
import { useToast } from "@/components/ui/use-toast";

type RouteParams = {
  task: { task: { id: string; parentId?: string } }
  subtask: { parentId: string; subtaskId: string }
  new_task: never
}

export function useCommonClientHooks() {
  const { toast } = useToast()

  const router = useRouter()
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const app = useUrlMaker()

  const routeTo = <T extends keyof RouteParams>(
    route: T = "task" as T,
    data: RouteParams[T],
  ) => {
    if (route === "task") {
      const taskData = data as RouteParams["task"]
      if (taskData.task.parentId) {
        const t = app.app.task
          ._id(taskData.task.parentId)
          .$url({ query: { subtask: taskData.task.id } }).path
        router.push(t)
      } else {
        const t = app.app.task._id(taskData.task.id).$url().path
        router.push(t)
      }
    }
  }

  return { router, searchParams, pathname, app, routeTo, toast }
}
