/* eslint-disable complexity */
"use client"

import { CustomIcon } from "../custom-icon"
import { cn } from "@/utils/utils"
import { Button } from "@/components/ui/button"
import React, { createRef, ReactNode, useEffect, useRef } from "react"
import { Textarea } from "@/components/ui/textarea"
import { Label } from "@/components/ui/label"
import { toast } from "@/components/ui/use-toast"
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog"
import { SelectModalStandalone } from "../teams/select-modal-standalone"
import { ColumnDef } from "@tanstack/react-table"
import { useCommonClientHooks } from "@/utils/custom-hooks"
import { createTaskFromTemplate } from "@/services/taskTemplate"
import { TravoTask } from "@/_drizzle/repositories/drizzleRepositories"
import { ModalExport } from "./custom/modal-export"
import { DashboardTask } from "@/services/SupabaseTravoApi"

export function showPageDialog(data: PageDialogProps) {
  const event = new CustomEvent("page-dialog", {
    detail: data,
  })
  window.dispatchEvent(event)
}

interface PageDialogProps {
  isOpen: boolean
  type:
    | "normal"
    | "error"
    | "info"
    | "warning"
    | "prompt"
    | "drop-zone"
    | "modal-select"
    | "modal-export"
  title: string
  mainMessage: ReactNode | string | null
  onClose: () => void
  onCloseText: string
  onConfirm: null | any
  onConfirmText: string
  dialogDescription?: React.ReactNode | null
  modalSelectData?: {
    templates: TravoTask[]
    columnsModalSelect: ColumnDef<TravoTask>[]
  } | null
  dataModalExport?: {
    selectedItems: DashboardTask[]
  } | null
}

export const PageDialog: React.FC = () => {
  const { app, router } = useCommonClientHooks()
  const modalRef = useRef<{ openModal(): void; closeModal(): void }>(null)

  const [data, setData] = React.useState<PageDialogProps>({
    isOpen: false,
    type: "normal",
    title: "Supprimer la tâche",
    mainMessage: "Êtes-vous sûr de vouloir supprimer cette tâche ?",
    onClose: () => {},
    onCloseText: "Annuler",
    onConfirm: () => {},
    onConfirmText: "Supprimer",
    dialogDescription: null,
    modalSelectData: null,
    dataModalExport: null,
  })

  useEffect(() => {
    window.addEventListener("page-dialog", (e: any) => {
      setData(e.detail)
      if (e.detail.type === "modal-select") {
        modalRef.current?.openModal()
      }
    })

    return () => {
      window.removeEventListener("page-dialog", (e: any) => {
        setData(e.detail)
      })
    }
  }, [])

  const {
    isOpen,
    type,
    title,
    mainMessage,
    dataModalExport,
    onClose,
    onCloseText,
    onConfirm,
    onConfirmText,
    dialogDescription,
  } = data

  const [files, setFiles] = React.useState<File[]>([])
  const attachmentsRef = createRef<HTMLInputElement>()
  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const _files = event.target.files
    if (!_files || _files.length === 0) {
      return
    }

    setFiles((prev) => [...prev, ...Array.from(_files)])
  }

  function onConfirmFiles(_: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!files || files.length === 0) {
      toast({
        description: "Aucun fichier a été ajouté!",
        variant: "destructive",
      })

      return
    }

    const uploadedFiles = Array.from(files).map((file) => ({
      file,
    }))

    onConfirm(uploadedFiles)

    setFiles([])
    toast({
      description: "Les fichiers ont été enregistrés",
    })
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const { files: _files } = e.dataTransfer
    setFiles((prev) => [...prev, ...Array.from(_files)])
    const dropZone = document.getElementById("drop-zone-files")
    if (!dropZone) return
    dropZone.style.border = ""
    dropZone.classList.add("border-spaced-dotted")
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    // change the color of the border
    const dropZone = document.getElementById("drop-zone-files")
    if (!dropZone) return
    dropZone.classList.remove("border-spaced-dotted")
    dropZone.style.border = "2px dashed rgb(0, 82 ,153)"
  }

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    // change the color of the border
    const dropZone = document.getElementById("drop-zone-files")
    if (!dropZone) return
    dropZone.style.border = ""
    dropZone.classList.add("border-spaced-dotted")
  }

  function onConfirmPrompt() {
    const textArea = document.getElementById("message") as HTMLTextAreaElement
    onConfirm(textArea.value)
  }

  function onCloseDialog() {
    setFiles([])
    setData((d) => ({ ...d, isOpen: false }))
    onClose()
  }

  if (type === "modal-export") {
    return (
      <>
        <div id="page-dialog"></div>
        <ModalExport
          open={isOpen}
          dataModalExport={dataModalExport}
          onCloseDialog={onCloseDialog}
        />
      </>
    )
  }

  return (
    <>
      <div id="page-dialog"></div>

      <SelectModalStandalone<TravoTask>
        placeholderFilter={"Filtrez sur les noms, numéros ou dates..."}
        mode="single"
        ref={modalRef}
        textConfirmButton="Créer"
        title="Modèles de tâche"
        items={data.modalSelectData?.templates ?? []}
        columns={data.modalSelectData?.columnsModalSelect ?? []}
        onConfirm={async (items) => {
          if (items.length === 0) return
          if (items.length > 1) return

          if (items.length === 1 && items[0]) {
            const templateId = items[0]
            const newTask = await createTaskFromTemplate(templateId)
            if (!newTask) {
              console.error("error : ", "newTask is null")
              toast({
                description: "Une erreur est survenue durant la création de la tâche",
                variant: "destructive",
              })
              return
            }

            router.push(app.app.task._id(newTask.id).$url().path)
          }
        }}
      />

      <AlertDialog open={isOpen && type !== "modal-select"}>
        <AlertDialogContent
          className={cn(
            type === "error" && "border-t-8 border-t-error-text",
            type === "warning" && "border-t-8 border-t-warning-deco",
            type === "info" && "border-t-8 border-t-forecast-blue-light",
          )}
        >
          <AlertDialogHeader>
            <AlertDialogTitle className="flex gap-4 items-center">
              {type === "error" && (
                <CustomIcon type="Icon-Error" className="!h-8 !w-8 text-error-text" />
              )}
              {type === "warning" && (
                <CustomIcon type="Icon-Warning" className="!h-8 !w-8 text-warning-deco" />
              )}
              {type === "info" && (
                <CustomIcon type="Icon-Info" className="!h-8 !w-8 forecast-blue-light" />
              )}
              <div className="text-700-24">{title}</div>
            </AlertDialogTitle>
            {dialogDescription ? (
              <AlertDialogDescription className="ml-12 !mt-8">
                {dialogDescription}
              </AlertDialogDescription>
            ) : (
              <div
                className={
                  cn(
                    "!my-8",
                    (type === "error" || type === "warning" || type === "info") &&
                      "ml-12",
                  ) + " text-500-16"
                }
              >
                {mainMessage}
              </div>
            )}

            {type === "prompt" && (
              <div className="grid w-full gap-1.5">
                <Label htmlFor="message">Remarques pour le demandeur</Label>
                <Textarea placeholder="" id="message" />
              </div>
            )}

            {type === "drop-zone" && (
              <>
                <input
                  className="hidden"
                  ref={attachmentsRef}
                  multiple
                  placeholder="Picture"
                  type="file"
                  accept="image/*, application/pdf"
                  onChange={(event) => {
                    handleFileChange(event)
                  }}
                />

                <div
                  id="drop-zone-files"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragLeave={onDragLeave}
                  className="h-[120px] flex items-center border-spaced-dotted  px-6 py-8"
                >
                  <div className="h-14 flex justify-center">
                    <div className="flex justify-center items-center">
                      <CustomIcon type="Icon-upload" className="!h-8 !w-8 mr-6" />
                    </div>
                    <div className="flex flex-col justify-center ">
                      <div className="flex justify-center items-baseline">
                        <div className="">Déposer le fichier ici ou</div>
                        <div
                          onClick={(_e) => {
                            attachmentsRef.current?.click()
                          }}
                          className="cursor-pointer text-600-14 text-forecast-blue-readable-light px-4 py-3"
                        >
                          Parcourir sur l&apos;ordinateur
                        </div>
                      </div>
                      <div className="text-500-12 text-black-light">
                        Jpeg, png, docx, xls ou pdf uniquement - 8 Mo maximum
                      </div>
                    </div>
                  </div>
                </div>
                {files.length > 0 && (
                  <div className=" pt-8">
                    <div className="text-500-16 mb-2.5">Liste des fichiers</div>
                    <div className="text-400-16">
                      {files.map((file, index) => (
                        <div key={index} className="h-10 flex items-center gap-2">
                          <div className="w-full flex items-center">
                            <CustomIcon type="Icon-Image" className="!h-10 !w-10 mr-2" />
                            <div>{file.name}</div>
                            <div className="flex-1" />
                            <CustomIcon
                              type="Icon-delete-garbage"
                              className="!h-6 !w-6"
                              onClick={(_) => {
                                setFiles((prev) => prev.filter((__, i) => i !== index))
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}

            <AlertDialogFooter className="h-[65px] items-end  justify-end">
              {onCloseText && (
                <Button type="button" variant="forecastOutline" onClick={onCloseDialog}>
                  {onCloseText}
                </Button>
              )}
              {onConfirm && (
                <Button
                  type="button"
                  variant="forecast"
                  disabled={type === "drop-zone" && files.length === 0}
                  onClick={(_) => {
                    setData({ ...data, isOpen: false })
                    if (type === "drop-zone") {
                      onConfirmFiles(_)
                    } else if (type === "prompt") {
                      onConfirmPrompt()
                    } else {
                      onConfirm()
                    }
                  }}
                >
                  {onConfirmText}
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
