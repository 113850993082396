// import { CloseCircle } from "mdi-material-ui"
// <CloseCircle className="!h-10 !w-10" style={{ color: "#D4D4D8" }} />

import { CustomIcon } from "./custom-icon"

export function EmptyBlock({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex items-center h-10">
      <CustomIcon type="Icon-Empty-Space" className="!h-10 !w-10 mr-2 text-zinc-300" />
      <div className="font-medium">{children}</div>
    </div>
  )
}
