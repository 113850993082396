import type { OptionalQuery as OptionalQuery_1hjvwr6 } from '../app/[lang]/app/task/[id]/page';
import type { Query as Query_26hbf2 } from '../app/[lang]/login/page';

const buildSuffix = (url?: {query?: Record<string, string>, hash?: string}) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  _lang: (lang: string | number) => ({
    "app": {
      "new_task": {
        $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/new-task' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/new-task${buildSuffix(url)}` })
      },
      "locations": {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/locations/[id]' as const, query: { lang, id }, hash: url?.hash, path: `/${lang}/app/locations/${id}${buildSuffix(url)}` })
        }),
        "create": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/locations/create' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/locations/create${buildSuffix(url)}` })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/locations' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/locations${buildSuffix(url)}` })
      },
      "people": {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/people/[id]' as const, query: { lang, id }, hash: url?.hash, path: `/${lang}/app/people/${id}${buildSuffix(url)}` })
        }),
        "create": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/people/create' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/people/create${buildSuffix(url)}` })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/people' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/people${buildSuffix(url)}` })
      },
      "portal": {
        $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/portal' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/portal${buildSuffix(url)}` })
      },
      "requests": {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/requests/[id]' as const, query: { lang, id }, hash: url?.hash, path: `/${lang}/app/requests/${id}${buildSuffix(url)}` })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/requests' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/requests${buildSuffix(url)}` })
      },
      "services": {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/services/[id]' as const, query: { lang, id }, hash: url?.hash, path: `/${lang}/app/services/${id}${buildSuffix(url)}` })
        }),
        "create": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/services/create' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/services/create${buildSuffix(url)}` })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/services' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/services${buildSuffix(url)}` })
      },
      "task": {
        "@operations": {
          "___create": {
            $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/task/@operations/(.)create' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/task/@operations/(.)create${buildSuffix(url)}` })
          }
        },
        _id: (id: string | number) => ({
          $url: (url?: { query?: OptionalQuery_1hjvwr6, hash?: string }) => ({ pathname: '/[lang]/app/task/[id]' as const, query: { lang, id, ...url?.query }, hash: url?.hash, path: `/${lang}/app/task/${id}${buildSuffix(url)}` })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/task' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/task${buildSuffix(url)}` })
      },
      "teams": {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/teams/[id]' as const, query: { lang, id }, hash: url?.hash, path: `/${lang}/app/teams/${id}${buildSuffix(url)}` })
        }),
        "create": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/teams/create' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/teams/create${buildSuffix(url)}` })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/teams' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/teams${buildSuffix(url)}` })
      },
      "templates": {
        $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app/templates' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app/templates${buildSuffix(url)}` })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/app' as const, query: { lang }, hash: url?.hash, path: `/${lang}/app${buildSuffix(url)}` })
    },
    "email_sent": {
      $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/email-sent' as const, query: { lang }, hash: url?.hash, path: `/${lang}/email-sent${buildSuffix(url)}` })
    },
    "login": {
      $url: (url: { query: Query_26hbf2, hash?: string }) => ({ pathname: '/[lang]/login' as const, query: { lang, ...url.query }, hash: url.hash, path: `/${lang}/login${buildSuffix(url)}` })
    },
    "password_forgotten": {
      $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/password-forgotten' as const, query: { lang }, hash: url?.hash, path: `/${lang}/password-forgotten${buildSuffix(url)}` })
    },
    "password_reset": {
      $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/password-reset' as const, query: { lang }, hash: url?.hash, path: `/${lang}/password-reset${buildSuffix(url)}` })
    },
    "public": {
      _organization: (organization: string | number) => ({
        "request_done": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/public/[organization]/request-done' as const, query: { lang, organization }, hash: url?.hash, path: `/${lang}/public/${organization}/request-done${buildSuffix(url)}` })
        },
        "requests": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[lang]/public/[organization]/requests' as const, query: { lang, organization }, hash: url?.hash, path: `/${lang}/public/${organization}/requests${buildSuffix(url)}` })
        }
      })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/[lang]' as const, query: { lang }, hash: url?.hash, path: `/${lang}${buildSuffix(url)}` })
  }),
};

export type PagesPath = typeof pagesPath;
