"use client"

import {
  ColumnDef,
  flexRender,
  ColumnFiltersState,
  getFilteredRowModel,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Input } from "@/components/ui/input"
import React from "react"
import { DataTablePagination } from "./DataTablePagination"
import { cn } from "@/utils/utils"

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  placeholderFilter?: string
  onRowSelectionChange: any
  selectedRowIds: Record<string, boolean>
  searchClassName?: string
  searchColumn?: string
}

export function DataTable<TData extends { id: string }, TValue>({
  columns,
  data,
  placeholderFilter = "Filtrer",
  onRowSelectionChange,
  selectedRowIds = {},
  searchClassName,
  searchColumn = "fullname",
  noPagingForSelectedItems = false,
}: DataTableProps<TData, TValue> & { noPagingForSelectedItems?: boolean }) {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])

  const table = useReactTable({
    data,
    columns,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id,
    onRowSelectionChange,
    state: {
      columnFilters,
      rowSelection: selectedRowIds,
    },
  })

  const fullNameCol = table.getColumn(searchColumn)

  return (
    <div className="w-full">
      <Input
        placeholder={placeholderFilter}
        value={(fullNameCol?.getFilterValue() as string) ?? ""}
        onChange={(event) => {
          fullNameCol?.setFilterValue(event.target.value)
        }}
        className={cn("max-w-sm border-grey-inputfields-border mb-4", searchClassName)}
      />

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  Aucun résultat
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination
        table={table}
        noPagingForSelectedItems={noPagingForSelectedItems}
      />
    </div>
  )
}
