"use client"
import React, { forwardRef, type Ref, useImperativeHandle, useState } from "react"

import { Trans } from "@lingui/macro"
import { Button } from "@/components/ui/button"

import { Chip } from "../chip"
import { DataTable } from "../tables/basic-table"
import {
  AlertDialog,
  AlertDialogDescription,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogFooter,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog"

interface PropsInterface<T> {
  name?: string
  description?: React.ReactNode
  items: T[]
  title: React.ReactNode
  columns?: any
  onConfirm: (items: string[]) => void

  textConfirmButton: string
  mode: "single" | "multiple"
  placeholderFilter: string
}

interface ItemInterface {
  id: string
  title: string
}

type RefType = Ref<{ openModal(): void; closeModal(): void }>

const _SelectModalStandalone = <T extends ItemInterface>(
  {
    name: _ = "",
    description = "",
    items = [],
    title,
    columns,
    onConfirm,
    textConfirmButton,
    mode,
    placeholderFilter,
  }: PropsInterface<T>,
  ref: RefType,
) => {
  const [open, setOpen] = useState(false)

  const [rowSelection, setRowSelection] = React.useState<Record<string, boolean>>(() => {
    return {}
  })

  useImperativeHandle(ref, () => ({
    openModal() {
      setOpen(true)
    },
    closeModal() {
      setOpen(false)
    },
  }))

  const dialog = (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent
        className="max-w-[768px] "
        // style={{ maxHeight: "calc(100vh - 80px)" }}
      >
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center">{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <div className="dialog-body gap-4 py-4 pt-0 pb-0">
          {Object.values(rowSelection).length > 0 ? (
            <div className="flex flex-wrap gap-2 items-center mb-2">
              <div>Sélectionnés: </div>
              {Object.keys(rowSelection).map((teamId) => {
                const team = items.find((t) => t.id === teamId)
                if (!team) return null
                return (
                  <Chip
                    key={teamId}
                    label={
                      <div className="flex gap-1 items-center text-forecast-blue-readable">
                        {team.title}
                      </div>
                    }
                    blue
                  />
                )
              })}
            </div>
          ) : null}
          <DataTable
            noPagingForSelectedItems
            searchColumn={"title"}
            columns={columns}
            data={items}
            selectedRowIds={rowSelection}
            placeholderFilter={placeholderFilter}
            onRowSelectionChange={(value: any) => {
              if (mode === "single") {
                const newValue = value()
                setRowSelection(newValue)
              } else {
                setRowSelection(value)
              }
            }}
            searchClassName="w-[300px]"
          />
        </div>
        <AlertDialogFooter>
          <Button
            type="button"
            variant="forecastOutline"
            onClick={(__) => {
              setOpen(false)
            }}
          >
            <Trans>Annuler</Trans>
          </Button>

          <Button
            type="button"
            variant="forecast"
            disabled={Object.keys(rowSelection).length === 0}
            onClick={(__) => {
              setOpen(false)
              onConfirm(Object.keys(rowSelection))
            }}
          >
            {textConfirmButton}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )

  return <div className="flex justify-end">{dialog}</div>
}

// Utilisation de forwardRef pour permettre le passage de refs
export const SelectModalStandalone = forwardRef(_SelectModalStandalone) as <T>(
  props: PropsInterface<T> & { ref?: RefType },
) => ReturnType<typeof _SelectModalStandalone>
