import { format, addDays, parseISO } from "date-fns"
import { cn } from "@/utils/utils"
import { fr } from "date-fns/locale"

import type { DateRange } from "react-day-picker"

export type { DateRange }

export { addDays }

export function formatDateRange(date: DateRange | undefined) {
  if (!date) return null
  if (!date.from) {
    return <span></span>
  }

  if (date.to) {
    return (
      <>
        {format(date.from, "LLL dd, y")} - {format(date.to, "LLL dd, y")}
      </>
    )
  }

  return format(date.from, "LLL dd, y")
}

/* export function DateDisplay({ date }: { date: Date }) {
  return <time dateTime={date.toISOString()}>{date.toLocaleDateString()}</time>
} */

export function DateDisplay({
  date,
  string,
  className,
}: {
  date?: Date | null | undefined
  string?: string | null | undefined
  className?: string
}) {
  /*     const { _, i18n } = useLingui()
    const formatter = new Intl.DateTimeFormat(i18n.locale, {
      weekday: "short",
      day: "numeric",
      month: "long",
      year: "numeric",
    })
    const editedAtLabel = task?.edited_at ? format(task.edited_at, "PPP") : "-"
  
 */
  if (string) date = parseISO(string)

  return <div className={cn("", className)}>{date ? formatDateFromDate(date) : "-"}</div>
}

export function formatTimeFromDate(dateDate: Date) {
  return (
    format(dateDate, "HH:mm", {
      locale: fr,
    }) ?? "-"
  )
}

export function formatDateFromDate(dateDate: Date, type: "short" | "long" = "long") {
  if (isNaN(dateDate.getTime())) {
    throw new Error("Invalid date value")
  }
  if (type === "short") {
    return (
      format(dateDate, "dd MMM, yyyy", {
        locale: fr,
      }) ?? "-"
    )
  }
  return (
    format(dateDate, "EEE dd MMM yyyy", {
      locale: fr,
    }) ?? "-"
  )
}

export function formatDateFromString(
  dateDate: string,
  type: "short" | "long" = "long",
): string {
  if (isNaN(parseISO(dateDate).getTime())) {
    return "-"
  }

  if (type === "short") {
    return (
      format(dateDate, "dd MMM, yyyy", {
        locale: fr,
      }) ?? "-"
    )
  }
  return (
    format(dateDate, "EEE dd MMM yyyy", {
      locale: fr,
    }) ?? "-"
  )
}

// export function formatDateFromString(dateString: string) {
//   return (
//     format(parseISO(dateString), "EEE dd MMM yyyy", {
//       locale: fr,
//     }) ?? "-"
//   )
// }
