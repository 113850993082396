/* eslint-disable complexity */
"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Checkbox } from "@/components/ui/checkbox"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"

import {
  AlertDialog,
  AlertDialogTitle,
  AlertDialogContent,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"

import * as XLSX from "xlsx"
import { DashboardTask } from "@/services/SupabaseTravoApi"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { useState } from "react"
import { useCommonClientHooks } from "@/utils/custom-hooks"
import { cn } from "@/utils/utils"


interface Person {
  id: string;
  createdAt: string;
  nationalId: string;
  name: string;
  surname: string;
  userId: string | null;
  organizationId: string;
  email: string | null;
  phoneNumber: string | null;
  jobTitle: string | null;
  createdBy: string;
  modifiedBy: string | null;
  modifiedAt: string | null;
  status: "active" | string;
}

interface Assignment {
  assignmentId: string;
  personId: string;
  licensePlate: string;
  assignmentDate: string;
  durationHours: number;
  durationMinutes: number;
  person: Person;
}

interface TaskAssignment {
  id: string;
  taskId: string;
  type: "individual" | string;
  createdAt: string;
  people: Assignment[];
}

interface ExportAssignation {
  "Numéro de tâche": string;
  "Description": string;
  "Nom": string;
  "Prénom": string;
  "Date": string;
  "Véhicule": string;
  "Localisation": string;
  "Durée": string;
}

interface ExportTaskItem {
  "Numéro de tâche": string;
  "Titre": string;
  "Créée le": string;
  "Date de la demande": string;
  "Date de planification": string;
  "Equipes et services": string;
  "Travailleurs": string;
}


function exportTaskskList(taskList: ExportTaskItem[], assignations: ExportAssignation[]) {
  const workbook = XLSX.utils.book_new()

  const worksheet = XLSX.utils.json_to_sheet(taskList)
  XLSX.utils.book_append_sheet(workbook, worksheet, "Tâches")

  const worksheetAssignations = XLSX.utils.json_to_sheet(assignations)
  XLSX.utils.book_append_sheet(workbook, worksheetAssignations, "Assignations")


  XLSX.writeFile(workbook, "tâches.xlsx")

}

function exportTaskList(submittedForm: FormSchemaType, dataModalExport: { selectedItems: DashboardTask[] }) {
  if (!dataModalExport || dataModalExport.selectedItems.length === 0) {
    throw new Error("Aucune tâche sélectionnée")
  }


  let exportData = dataModalExport.selectedItems.map((item) => ({
    "Numéro de tâche": item.taskNumber,
    "Titre": item.title,
    "Créée le": item.createdAt,
    "Date de la demande": item.requestDate ?? "",
    "Date de planification": item.taskPlannedDates
      .map((date) => date.plannedDate + " " + date.plannedTime)
      .join(", "),
    "Equipes et services": [
      ...item.teams.map((team) => team.name),
      ...item.services.map((service) => service.name),
    ].join(", "),
    "Travailleurs": item.assignations
      .map((assignation) =>
        assignation.people.map((person) => person.person?.name).join(", "),
      )
      .join(", "),
  }))


  if (submittedForm.type === "request-date" && !submittedForm.includeNonRequested) {
    exportData = exportData.filter((item) => item["Date de la demande"] !== "")
  }
  if (submittedForm.type === "planned-date" && !submittedForm.includeNonPlanned) {
    exportData = exportData.filter((item) => item["Date de planification"] !== "")
  }
  if (submittedForm.type === "teams-services" && !submittedForm.includeNonTeamsOrServices) {
    exportData = exportData.filter((item) => item["Equipes et services"] !== "")
  }
  if (submittedForm.type === "workers" && !submittedForm.includeNonAssigned) {
    exportData = exportData.filter((item) => item["Travailleurs"] !== "")
  }

  if (exportData.length === 0) {
    throw new Error("Aucune tâche ne correspond aux critères de filtrage")
  }

  switch (submittedForm.type) {
    case "created-date":
      exportData.sort((a, b) =>
        (a["Créée le"] || "").localeCompare(b["Créée le"] || ""),
      )
      break
    case "request-date":
      exportData.sort((a, b) =>
        (a["Date de la demande"] || "").localeCompare(b["Date de la demande"] || ""),
      )
      break
    case "planned-date":
      exportData.sort((a, b) =>
        (a["Date de planification"] || "").localeCompare(
          b["Date de planification"] || "",
        ),
      )
      break
    case "teams-services":
      exportData.sort((a, b) =>
        (a["Equipes et services"] || "").localeCompare(b["Equipes et services"] || ""),
      )
      break
    case "workers":
      exportData.sort((a, b) =>
        (a["Travailleurs"] || "").localeCompare(b["Travailleurs"] || ""),
      )
      break
  }

  const assignations: ExportAssignation[] = []
  for (const item of dataModalExport.selectedItems) {
    for (const assignation of item.assignations as TaskAssignment[]) {
      for (const people of assignation.people) {
        assignations.push({
          "Numéro de tâche": item.taskNumber,
          "Description": item.description ?? "",
          "Nom": people.person.name,
          "Prénom": people.person.surname,
          "Date": people.assignmentDate,
          "Véhicule": people.licensePlate,
          "Localisation": item.locations.map((location) => (location.address as any).string).join(", "),
          "Durée": people.durationHours + "," + people.durationMinutes / 60,
        })
      }
    }
  }
  exportTaskskList(exportData, assignations)

}

const FormSchema = z.object({
  type: z.enum(["request-date", "created-date", "planned-date", "teams-services", "workers"], {
    required_error: "Veuillez sélectionner un type",
  }),
  includeNonRequested: z.boolean(),
  includeNonPlanned: z.boolean(),
  includeNonTeamsOrServices: z.boolean(),
  includeNonAssigned: z.boolean(),
})

type FormSchemaType = z.infer<typeof FormSchema>

export function ModalExport({
  open,
  dataModalExport,
  onCloseDialog,
}: {
  open: boolean
  dataModalExport: { selectedItems: DashboardTask[] } | null | undefined
  onCloseDialog: () => void
}) {
  const { toast } = useCommonClientHooks()
  const [isExporting, setIsExporting] = useState(false)
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      type: "created-date",
      includeNonRequested: true,
      includeNonPlanned: true,
      includeNonTeamsOrServices: true,
      includeNonAssigned: true,
    },
  })

  // onClick : make export with xlsx of selected items
  function onSubmit(submittedForm: FormSchemaType) {
    if (isExporting || !dataModalExport) {
      return;
    }
    setIsExporting(true)

    try {
      exportTaskList(submittedForm, dataModalExport)
      toast({
        description: "Exportation réussie",
      })
    } catch (error) {
      console.error(error)
      toast({
        description: "Une erreur est survenue lors de l'exportation",
        variant: "destructive",
      })
    } finally {
      setTimeout(() => {
        setIsExporting(false);
      }, 1000);
    }
  }


  if (!dataModalExport) {
    return null
  }

  const value = form.watch("type")

  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Exporter {dataModalExport.selectedItems.length > 1 ? "les tâches sélectionnées" : "la tâche sélectionnée"}
          </AlertDialogTitle>
        </AlertDialogHeader>


        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel>Vous avez sélectionné {dataModalExport.selectedItems.length} tâches. Vous souhaitez trier les tâches par :</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                    >
                      <FormItemCustom
                        label="Date de création"
                        displayCheckbox={false}
                        checked={false}
                        onChange={(_) => null}
                        value="created-date"
                        onCheckedText=""
                      />
                      <FormItemCustom
                        label="Date de demande"
                        displayCheckbox={value === "request-date"}
                        checked={form.getValues("includeNonRequested")}
                        onChange={(checked) => {
                          form.setValue("includeNonRequested", checked)
                          form.trigger("includeNonRequested")
                        }}
                        value="request-date"
                        onCheckedText="Inclure les tâches non demandées"
                        disabled={dataModalExport.selectedItems.every((item) => !item.requestDate)}
                      />
                      <FormItemCustom
                        label="Date de planification"
                        displayCheckbox={value === "planned-date"}
                        checked={form.getValues("includeNonPlanned")}
                        onChange={(checked) => {
                          form.setValue("includeNonPlanned", checked)
                          form.trigger("includeNonPlanned")
                        }}
                        value="planned-date"
                        onCheckedText="Inclure les tâches non planifiées"
                        disabled={dataModalExport.selectedItems.every((item) => !item.taskPlannedDates.length)}
                      />
                      <FormItemCustom
                        label="Equipes et services"
                        displayCheckbox={value === "teams-services"}
                        checked={form.getValues("includeNonTeamsOrServices")}
                        onChange={(checked) => {
                          form.setValue("includeNonTeamsOrServices", checked)
                          form.trigger("includeNonTeamsOrServices")
                        }}
                        value="teams-services"
                        onCheckedText="Inclure les tâches sans équipes ou services"
                        disabled={dataModalExport.selectedItems.every((item) => !item.teams.length && !item.services.length)}
                      />
                      <FormItemCustom
                        label="Travailleurs"
                        displayCheckbox={value === "workers"}
                        checked={form.getValues("includeNonAssigned")}
                        onChange={(checked) => {
                          form.setValue("includeNonAssigned", checked)
                          form.trigger("includeNonAssigned")
                        }}
                        value="workers"
                        onCheckedText="Inclure les tâches non assignées"
                        disabled={dataModalExport.selectedItems.every((item) => !item.assignations.length)}
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-row justify-end gap-2">
              <Button type="button" variant="forecastOutline" onClick={onCloseDialog}>
                Annuler
              </Button>
              <Button type="submit" variant="forecast" className="">
                Exporter
              </Button>
            </div>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  )
}


function FormItemCustom({ label, displayCheckbox, checked, onChange, value, onCheckedText, disabled }: {
  label: string
  displayCheckbox: boolean
  checked: boolean
  onChange: (checked: boolean) => void
  value: string
  onCheckedText: string
  disabled?: boolean
}) {
  return (
    <FormItem className="">
      <div className="flex items-center">
        <FormControl>
          <RadioGroupItem value={value} disabled={disabled} />
        </FormControl>
        <FormLabel className={cn("font-normal", disabled && "text-gray-400")}>
          {label}
        </FormLabel>
      </div>
      {displayCheckbox &&
        <div className="flex items-center gap-2 ml-7">
          <Checkbox
            checked={checked}
            onCheckedChange={onChange}
          />
          <FormLabel className="font-normal">
            {onCheckedText}
          </FormLabel>
        </div>
      }
    </FormItem>
  )
}